
.password {
  padding: 80px 16px 131px;
  .card {
    max-width: 448px;
    background: #ffffff;
    box-shadow: 0px 2px 34px rgba(46, 48, 92, 0.11);
    margin: 0 auto;
    padding: 40px 56px;
    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 16px;
    }
    h1 {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
      text-align: center;

      color: #2e305c;
      margin-bottom: 10px;
    }
    p {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      /* or 142% */

      text-align: center;

      color: #000;
      margin-bottom: 40px;
    }
    .password-input {
      margin-bottom: 16px;
    }
    button.submit-btn {
      background: #fa8c28;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;

      color: #ffffff;
      width: 100%;
      padding: 22.5px 0;
      margin-top: 32px;
      &:hover {
        opacity: 0.9;
      }
      &.disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
  .msg {
    margin-bottom: 5px;
  }
  .footer {
      padding-top: 32px;
      padding-bottom: 22px;
      a {
        display: block;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        text-decoration-line: underline;

        color: #2e305c;
      }
    }
  @media screen and (max-width: 768px) {
    .card {
      padding: 40px 16px;
    }
  }
}
